import { FacebookRound, Instagram, Mail, Twitter } from "../components/icons";

import Layout from "../layouts";
import React from "react";

const ContactPage = () => (
  <Layout>
    <p className="leads-the-things emboldernised">
      You can reach us in one of the following ways...
    </p>

    <ul className="contact-us-without-a-dumb-contact-form">
      <li>
        <a href="https://twitter.com/SquarecatWebDev" title="@SquarecatWebDev">
          <Twitter />
          <span>Twitter</span>
        </a>
      </li>
      <li>
        <a href="https://facebook.com/SquarecatWebDev" title="@SquarecatWebDev">
          <FacebookRound />
          <span>Facebook</span>
        </a>
      </li>
      <li>
        <a
          href="https://instagram.com/squarecat_webdev"
          title="squarecat_webdev"
        >
          <Instagram />
          <span>Instagram</span>
        </a>
      </li>

      <li>
        <a href="mailto:hi@squarecat.io" title="hi@squarecat.io">
          <Mail />
          <span>Email</span>
        </a>
      </li>
    </ul>

    <p className="little-signoff">Can't wait to hear from you!</p>
  </Layout>
);

export default ContactPage;
